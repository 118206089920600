export const wmFR = {
  'skitouring': 'Ski de randonnée',
  'horse': 'À cheval',
  'walking': 'Marche',
  'running': 'Course',
  'cycling': 'Cyclisme',
  'poi_type': "Points d'intérêt",
  'where': 'Lieux',
  'lang': 'Langue',
  'langPlaceholder': 'Sélectionner',
  'from': 'Départ',
  'to': 'Arrivée',
  'ascent': 'Dénivelé positif',
  'descent': 'Dénivelé négatif',
  'distance': 'Distance',
  'ele_from': 'Altitude de départ',
  'ele_max': 'Altitude maximale',
  'ele_min': 'Altitude minimale',
  'ele_to': "Altitude d'arrivée",
  'difficulty': 'Difficulté',
  'duration_forward': 'Durée aller',
  'duration_backward': 'Durée retour',
  'Filtri': 'Filtres',
  'Cerca': 'Recherche',
  'tracce': 'Parcours',
  'hiking': 'Randonnée',
  'Escursionismo': 'Randonnée',
  'escursionismo': 'Randonnée',
  'i miei percorsi': 'Tracer un chemin',
  'metri': 'Mètres',
  'Vedi su OpenStreetMap': 'Voir sur OpenStreetMap',
  'Descrizione Audio': 'Description audio',
  'il tuo browser non supporta gli elementi audio':
    'Votre navigateur ne prend pas en charge les éléments audio.',
  'stampa': 'Imprimer',
  'Difficoltà': 'Difficulté',
  'Scala di difficoltà CAI': 'Échelle de difficulté CAI',
  'Questo percorso non è accessibile': "Ce parcours n'est pas accessible",
  'Descrizione': 'Description',
  'Punto di partenza': 'Point de départ',
  'Punto di arrivo': "Point d'arrivée",
  'Dettagli tecnici': 'Détails techniques',
  'Attività': 'Activités',
  'Come arrivare': 'Comment arriver',
  'Bici muscolari': 'Vélos musculaires',
  'Bici elettriche': 'Vélos électriques',
  'Mezzi pubblici': 'Transports en commun',
  'Auto Propria': 'Voiture personnelle',
  'Punti di interesse': "Points d'intérêt",
  'Collegamenti esterni': 'Liens externes',
  'Downloads': 'Téléchargements',
  'edit geohub': 'Modifier le geohub',
  'NCC': 'NCC',
  'Filtri attivi': 'Filtres actifs',
  'Reset filtri': 'Réinitialiser les filtres',
  'Torna alla home': "Retour à l'accueil",
  'Risultati': 'Résultats',
  'Caricamento in corso.': 'Chargement.',
  'Spiacenti non ci sono risultati con questi criteri di ricerca.':
    "Désolé, il n'y a aucun résultat avec ces critères de recherche",
  'Bentornato!': 'Bienvenue!',
  'Accedi con le tue credenziali per continuare.':
    'Connectez-vous avec vos identifiants pour continuer.',
  'E-mail': 'E-mail',
  "L'e-mail è obbligatoria": "L'e-mail est requis",
  "Hai inserito un'e-mail non valida": 'Vous avez entré un email invalide',
  'Password': 'Mot de passe',
  'La password è obbligatoria': 'Mot de passe requis',
  'Accedi': 'Connexion',
  'Hai dimenticato la password?': 'Avez-vous oublié votre mot de passe?',
  'Accedi e scarica i tuoi percorsi preferiti': 'Accédez et téléchargez vos itinéraires favoris',
  'Puoi anche condividerli con i tuoi compagni di viaggio':
    'Vous pouvez également les partager avec vos compagnons de voyage',
  'Registrati': "S'inscrire",
  'Tracce scaricate': 'Traces téléchargées',
  'Utilizzo dati': 'Utilisation de données',
  'Registrazioni': 'Archives',
  'Photos': 'Photos',
  'Waypoint': 'Waypoint',
  'Nome': 'Nom',
  'inserisci il tuo nome': 'Insérez votre nom',
  "E' necessario inserire un nome": 'Un nom doit être saisi',
  'Email': 'Email',
  "E' necessario inserire una email": "Il est nécessaire d'insérer un email",
  "L'email inserita non è un'email valida": "L'e-mail est invalide",
  'Codice Fiscale': 'Code fiscal',
  'Perché ti chiediamo il CF?': 'Pourquoi vous demande-t-on le CF?',
  'Il codice fiscale inserito non è valido': "Le code fiscal n'est pas valide",
  'inserisci password': 'Entrer le mot de passe',
  "E' necessario inserire la password": 'Un mot de passe doit être saisi',
  'Conferma password': 'Confirmez le mot de passe',
  'Ripeti password': 'Répétez le mot de passe',
  "E' necessario confermare la password": 'La confirmation du mot de passe est requise',
  'La conferma non corrisponde con la password inserita sopra':
    'La confirmation ne correspond pas au mot de passe saisi ci-dessus',
  'Cliccando su "Registrati" accetti la nostra':
    'En cliquant sur "S\'inscrire", vous acceptez notre',
  'e i': 'e i',
  'Termini e condizioni': 'ermes et conditions',
  "Errore durante la registrazione dell'utente": "Erreur lors de l'enregistrement de l'utilisateur",
  'Registrazione in corso': 'Inscription en cours',
  'Perchè ti chiediamo il Codice Fiscale?': 'Pourquoi vous demande-t-on le Code Fiscal ?',
  'Se sei Socia/o CAI inserisci il tuo CF al momento della registrazione. Per te il download delle tappe del Sentiero Italia CAI sarà automaticamente gratuito!':
    'Si vous êtes membre CAI, inscrivez votre CF lors de votre inscription. Pour vous, le téléchargement des étapes du Sentiero Italia CAI sera automatiquement gratuit !',
  "Origine dell'immagine": "Origine de l'image",
  'Annulla': 'Annuler',
  'Dalla libreria': 'De la bibliothèque',
  'Scatta una foto': 'Prendre une photo',
  'placeholder': 'Chercher',
  'Carica File': 'Charger le fichier',
  'Trascina qui il tuo file o clicca per selezionare':
    'Déplacez votre fichier ou cliquez pour sélectionner',
  'Accetta i seguenti formati': 'Accepte les formats suivants',
  'Tipo di file non supportato': 'Type de fichier non pris en charge',
  'Il file non contiene una geometria valida': 'Le fichier ne contient pas de géométrie valide',
  'Errore durante la lettura del file, riprova': 'Erreur lors de la lecture du fichier, réessayez',
  'Si è verificato un errore durante il salvataggio della traccia. Riprova':
    'Une erreur est survenue lors de la sauvegarde de la piste. Réessayez',
  'La traccia è stata salvata correttamente': 'La piste a été sauvegardée correctement',
  'Scarica il tracciato GPX': 'Télécharger le tracé GPX',
  'Scarica il tracciato KML': 'Télécharger le tracé KML',
  'Scarica il tracciato GEOJSON': 'Télécharger le tracé GEOJSON',
  'Link utili': 'Liens utiles',
  'apri pdf': 'Ouvrir le PDF',
  'pendenza': 'Pente',
  'Galleria': 'Galerie',
  'resetta la memoria': 'Réinitialiser la mémoire',
  'rimani acceso': 'Restez connecté',
  'forza il dispositivo a non spegnersi': 'Forcez le périphérique à ne pas s\'éteindre',
  'PAGINE': 'PAGES',
  'I tuoi dati': 'Vos données',
  'pages.profile.projectlink': 'Projet',
  'pages.profile.disclaimerlink': 'Avertissement',
  'pages.profile.creditslink': 'Crédits',
  'pages.profile.privacylink': 'Confidentialité',
  'pages.profile.title': 'Profil',
  'MEMORIA': 'MÉMOIRE',
  'la distanza espressa in metri che indica la cadenza di rilevamento posizione, più il distance filter è minore piu il consumo della batteria sarà maggiore.':
    'La distance exprimée en mètres indique la fréquence de détection de la position, plus le filtre de distance est faible, plus le consommation de la batterie sera élevée.',
  "Per registrare tracce e poi correttamente, abilita l'autorizzazione alla posizione nelle impostazioni":
    'Pour enregistrer les pistes et les points d\'intérêt correctement, activez la permission de position dans les paramètres.',
  'I miei percorsi': 'Mes parcours',
  'Sentieri': 'Sentiers',
  '<span class="green">Livello 1: tratti non interessati dall\'alta quota (quota minore di {{orange}} metri)</span>':
    '<span class="green">Niveau 1: sections non affectées par l\'altitude (altitude inférieure à {{orange}} mètres)</span>',
  '<span class="orange">Livello 2: tratti parzialmente in alta quota (quota compresa entre {{orange}} mètres et {{red}} metri)</span>':
    '<span class="orange">Niveau 2: sections partiellement en altitude (altitude comprise entre {{orange}} mètres et {{red}} mètres)</span>',
  '<span class="red">Livello 3: in alta quota (quota superiore {{red}} metri)</span>':
    '<span class="red">Niveau 3: sections en altitude (altitude supérieure à {{red}} mètres)</span>',
  'Un utente è già stato registrato con questa email.': 'Un utilisateur a déjà été enregistré avec cet email.',
  'L\'email inserita non è corretta. Per favore, riprova.': 'L\'email saisie est incorrecte. Veuillez réessayer.',
  'La password inserita non è corretta. Per favore, riprova.': 'Le mot de passe saisi est incorrect. Veuillez réessayer.',
  'Errore': 'Erreur',
};
