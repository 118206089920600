// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-ugc-poi-properties {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  background-color: white;
  font-style: normal;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
wm-ugc-poi-properties .wm-ugc-poi-form-readonly ion-card {
  box-shadow: none;
}
wm-ugc-poi-properties ion-header .wm-ugc-poi-details-header {
  font-size: var(--font-md);
  color: #333333;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 10px;
}
wm-ugc-poi-properties #wm-ugc-poi-details-content {
  width: 100%;
  height: calc(100% - 56px);
  font-family: var(--wm-font-family-content);
}
wm-ugc-poi-properties #wm-ugc-poi-details-content .wm-ugc-poi-title:first-letter {
  text-transform: uppercase;
}
wm-ugc-poi-properties #wm-ugc-poi-details-content .wm-ugc-poi-activity-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}
wm-ugc-poi-properties #wm-ugc-poi-details-content ion-grid {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/ugc-poi-properties/ugc-poi-properties.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,2BAAA;EACA,sBAAA;EACA,uBAAA;EACA,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,wBAAA,EAAA,gBAAA;EACA,qBAAA,EAAA,YAAA;AACF;AACI;EACE,gBAAA;AACN;AAGI;EACE,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,UAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;AADN;AAKE;EACE,WAAA;EACA,yBAAA;EACA,0CAAA;AAHJ;AAII;EACE,yBAAA;AAFN;AAII;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,2BAAA;EACA,yBAAA;EACA,uBAAA;AAFN;AAII;EACE,WAAA;AAFN","sourcesContent":["wm-ugc-poi-properties {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  justify-content: flex-start;\n  align-content: stretch;\n  align-items: flex-start;\n  background-color: white;\n  font-style: normal;\n  overflow: scroll;\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n  .wm-ugc-poi-form-readonly {\n    ion-card {\n      box-shadow: none;\n    }\n  }\n  ion-header {\n    .wm-ugc-poi-details-header {\n      font-size: var(--font-md);\n      color: #333333;\n      font-style: normal;\n      font-weight: 600;\n      display: flex;\n      align-items: center;\n      flex: none;\n      order: 0;\n      flex-grow: 0;\n      padding: 10px;\n    }\n  }\n\n  #wm-ugc-poi-details-content {\n    width: 100%;\n    height: calc(100% - 56px);\n    font-family: var(--wm-font-family-content);\n    .wm-ugc-poi-title:first-letter {\n      text-transform: uppercase;\n    }\n    .wm-ugc-poi-activity-content {\n      display: flex;\n      flex-direction: row;\n      flex-wrap: wrap;\n      justify-content: flex-start;\n      align-content: flex-start;\n      align-items: flex-start;\n    }\n    ion-grid {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
