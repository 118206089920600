// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-home .hidden {
  display: none;
}
wm-home ion-item ion-label {
  white-space: pre-wrap !important;
}
wm-home .root {
  width: 100%;
  height: 100%;
  padding: 1%;
  padding-bottom: 100px;
  overflow-y: auto;
}
wm-home .root ion-content {
  --padding-bottom: 100px;
}
wm-home .root .wm-home-cardcontainer {
  margin-bottom: 200px;
}
wm-home .pois {
  margin-bottom: 100px;
}
wm-home .wm-home-header-container {
  padding-top: 10px;
  display: block;
}
wm-home .wm-home-header-container ion-label {
  color: var(--wm-color-dark);
  display: block;
  font-size: var(--wm-font-xxxlg);
  font-weight: var(--wm-font-weight-bold);
}

.wm-modal .modal-wrapper {
  --height: 90%;
  --width: 90%;
  position: absolute;
  top: 5%;
  border-radius: 25px !important;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/home/home.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAGI;EACE,gCAAA;AADN;AAIE;EACE,WAAA;EACA,YAAA;EACA,WAAA;EACA,qBAAA;EACA,gBAAA;AAFJ;AAGI;EACE,uBAAA;AADN;AAGI;EACE,oBAAA;AADN;AAIE;EACE,oBAAA;AAFJ;AAIE;EACE,iBAAA;EACA,cAAA;AAFJ;AAGI;EACE,2BAAA;EACA,cAAA;EACA,+BAAA;EACA,uCAAA;AADN;;AAME;EACE,aAAA;EACA,YAAA;EACA,kBAAA;EACA,OAAA;EACA,8BAAA;EACA,cAAA;AAHJ","sourcesContent":["wm-home {\n  .hidden {\n    display: none;\n  }\n  ion-item {\n    ion-label {\n      white-space: pre-wrap !important;\n    }\n  }\n  .root {\n    width: 100%;\n    height: 100%;\n    padding: 1%;\n    padding-bottom: 100px;\n    overflow-y: auto;\n    ion-content {\n      --padding-bottom: 100px;\n    }\n    .wm-home-cardcontainer {\n      margin-bottom: 200px;\n    }\n  }\n  .pois {\n    margin-bottom: 100px;\n  }\n  .wm-home-header-container {\n    padding-top: 10px;\n    display: block;\n    ion-label {\n      color: var(--wm-color-dark);\n      display: block;\n      font-size: var(--wm-font-xxxlg);\n      font-weight: var(--wm-font-weight-bold);\n    }\n  }\n}\n.wm-modal {\n  .modal-wrapper {\n    --height: 90%;\n    --width: 90%;\n    position: absolute;\n    top: 5%;\n    border-radius: 25px !important;\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
