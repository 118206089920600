// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-geobox-map {
  width: 100%;
  height: 100%;
}
wm-geobox-map .webmapp-pageroute-map-container {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
}
wm-geobox-map .webmapp-pageroute-map-container > * {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
}
wm-geobox-map .webmapp-pageroute-map-container .webmapp-pageroute-map {
  width: 100%;
  display: block;
}
wm-geobox-map .webmapp-pageroute-map-container .opened {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/geobox-map/geobox-map.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EAGA,aAAA;EAGA,sBAAA;EAGA,iBAAA;EAGA,2BAAA;EAGA,sBAAA;EAGA,oBAAA;AAEJ;AAAI;EAGE,QAAA;EAGA,cAAA;EAGA,gBAAA;AAEN;AAAI;EACE,WAAA;EACA,cAAA;AAEN;AAAI;EACE,cAAA;AAEN","sourcesContent":["wm-geobox-map {\n  width: 100%;\n  height: 100%;\n  .webmapp-pageroute-map-container {\n    width: 100%;\n    height: 100%;\n    display: block;\n    position: relative;\n    display: -ms-flexbox;\n    display: -webkit-flex;\n    display: flex;\n    -webkit-flex-direction: column;\n    -ms-flex-direction: column;\n    flex-direction: column;\n    -webkit-flex-wrap: nowrap;\n    -ms-flex-wrap: nowrap;\n    flex-wrap: nowrap;\n    -webkit-justify-content: flex-start;\n    -ms-flex-pack: start;\n    justify-content: flex-start;\n    -webkit-align-content: stretch;\n    -ms-flex-line-pack: stretch;\n    align-content: stretch;\n    -webkit-align-items: stretch;\n    -ms-flex-align: stretch;\n    align-items: stretch;\n\n    > * {\n      -webkit-order: 0;\n      -ms-flex-order: 0;\n      order: 0;\n      -webkit-flex: 0 1 auto;\n      -ms-flex: 0 1 auto;\n      flex: 0 1 auto;\n      -webkit-align-self: auto;\n      -ms-flex-item-align: auto;\n      align-self: auto;\n    }\n    .webmapp-pageroute-map {\n      width: 100%;\n      display: block;\n    }\n    .opened {\n      display: block;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
