export const appIT = {
  'activities': {
    'cycling': 'Bicicletta',
    'hiking': 'Hiking',
    'running': 'Corsa',
    'skitouring': 'Scialpinismo',
    'walking': 'Passeggiata',
    'asphalt': 'Asfalto',
    'bitumenduro': 'Bitumenduro',
    'onoff': 'Onoff',
    'real_dirt': 'Sterrato vero',
    'bar': 'Bar',
  },
  'sidemenu': {
    'project': 'Il Progetto',
    'offline': 'Offline',
    'settings': 'Impostazioni',
    'credits': 'Credits',
    'disclaimer': 'Disclaimer',
  },
  'components': {
    'cardtrack': {
      'delete': 'Elimina',
      'remove': 'Rimuovi',
    },
    'map': {
      'btnrec': {
        'slide': 'Scorri per registrare',
      },
      'register': {
        'cancel': 'Annulla',
        'photo': 'Foto',
        'title': 'Registra',
        'track': 'Tracciato',
        'vocal': 'Nota vocale',
        'waypoint': 'Waypoint',
      },
    },
    'searchbar': {
      'filters': 'Filtra per',
      'nofilters': 'Nessun filtro corrisponde alla ricerca effetuata',
      'noplaces': 'Nessun luogo corrisponde alla ricerca effetuata',
      'notracks': 'Nessun percorso corrisponde alla ricerca effetuata',
      'placeholder': 'cerca',
      'places': 'Luoghi',
      'tracks': 'Percorsi',
    },
    'slopechart': {
      'slope': {
        'label': 'pendenza',
      },
      'surfaces': {
        'asphalt': {
          'label': 'asfalto',
        },
        'concrete': {
          'label': 'cemento',
        },
        'dirt': {
          'label': 'terra',
        },
        'grass': {
          'label': 'erba',
        },
        'gravel': {
          'label': 'ghiaia',
        },
        'paved': {
          'label': 'lastricato',
        },
        'sand': {
          'label': 'sabbia',
        },
      },
    },
  },
  'generic': {
    'cancel': 'annulla',
    'confirm': 'conferma',
    'delete': 'cancella',
    'error': 'Si è verificato un errore. Riprova più tardi',
    'hour': 'h',
    'minute': 'min',
    'ok': 'ok',
    'retry': 'riprova',
    'warning': 'Attenzione',
  },
  'modals': {
    'coin': {
      'buttonall': 'Vedi tutti i coin pack',
      'buttonone': 'Acquista 1 Coin / 1€',
      'defaultmessage': 'Acquista coins per scaricare contenuti offline',
      'info1': 'Puoi acquistare un singolo percorso',
      'info2': 'Hai tutto a disposizione anche senza copertura',
      'info3': 'Ricevi notifiche di prossimità',
      'subinfo1': 'Acquista un singolo coin a 1€',
      'subinfo2': 'Navigazione precis aanche queando sei offline',
      'subinfo3': 'Ti avvisiamo in prossimità di punti di interesse sul percorso',
    },
    'giftcoin': {
      'button': 'Inizia a esplorare',
      'info': 'Hai subito in regalo 2 webmapp coins per scaricare contenuti offline!',
      'title': 'Benvenuto in webmapp!',
    },
    'login': {
      'email': 'E-mail',
      'errors': {
        '401':
          'Le credenziali che hai inserito non sono corrette. Controlla e-mail e password e riprova',
        'form': {
          'emailInvalid': "Hai inserito un'e-mail non valida",
          'emailRequired': "L'e-mail è obbligatoria",
          'passwordRequired': 'La password è obbligatoria',
        },
        'generic': 'Oops! Qualcosa è andato storto! Attendi qualche minuto e riprova.',
      },
      'forgotPassword': 'Hai dimenticato la password?',
      'login': 'Accedi',
      'message': 'Accedi con le tue credenziali per continuare.',
      'password': 'Password',
      'rememberLogin': 'Ricorda le credenziali',
      'title': 'Accedi',
      'welcome': 'Bentornato!',
    },
    'photo': {
      'next': 'Avanti',
      'popover': {
        'cancel': 'Annulla',
        'library': 'Dalla libreria',
        'shot': 'Scatta una foto',
        'title': "Origine dell'immagine",
      },
      'save': {
        'addphoto': 'Aggiungi foto',
        'formphototitle': 'Titolo ',
        'formtitle': 'Dai un titolo alle tue foto',
        'modalconfirm': {
          'cancel': 'Annulla',
          'confirm': 'Sì, elimina',
          'text': 'Se la elimini non verrà aggiunta a questa registrazione',
          'title': 'Sei sicuro di voler eliminare questa foto dalla registrazione?',
        },
        'placeholder': 'Qui puoi inserire un titolo',
        'savebtn': 'Salva',
        'title': 'Registra foto',
      },
      'single': {
        'formphototitle': 'Titolo',
        'placeholder': 'Qui puoi inserire un titolo',
        'savebtn': 'Fatto',
        'title': 'Modifica foto',
      },
    },
    'settings': {
      'alert': {
        'logout': 'Sicuro di voler disconnettere il tuo account?',
      },
      'logout': 'Log out',
      'title': 'Impostazioni',
      'version': 'Versione',
    },
    'storesuccess': {
      'back': 'Torna alla ricerca',
      'buttondownload': 'Scarica adesso',
      'continue': 'Vuoi utilizzare subito il tuo Coin per scaricare ',
      'continue2': '?',
      'info': 'Puoi controllare il saldo dei tuoi coin direttamente nella tua area personale!',
      'or': 'Oppure',
      'title': 'Grazie per aver acquistato il tuo primo coin!',
    },
    'success': {
      'photos': {
        'text':
          'Le tue foto sono state salvate correttamente. Puoi visualizzarle accedendo al to profilo.',
        'title': 'Foto registrate con successo!',
      },
      'return': 'Torna ad esplorare',
      'track': {
        'avgspeed': 'Velocità media',
        'odo': 'Km percorsi',
        'slope': 'Dislivello',
        'text':
          'La traccia è stata salvata correttamente. Puoi visualizzarla accedendo al tuo profilo.',
        'time': 'Tempo',
        'title': 'Attività registrata con successo!',
        'topspeed': 'Velocità massima',
      },
      'waypoint': {
        'text':
          'Hai salvato correttamente il tuo waypoint. Puoi visualizzarlo accedendo al tuo profilo.',
        'title': 'Waypoint registrato con successo!',
      },
    },
  },
  'pages': {
    'downloadlist': {
      'cancel': 'Annulla',
      'deleteselected': 'Elimina selezionati',
      'nodata': 'Non hai ancora scaricato nessuna traccia',
      'nodata2': 'Con i contenuti per uso offline navighi in tranquillità anche senza connessione!',
      'nodatabtn': 'Esplora',
      'select': 'Seleziona',
      'title': 'Downloads',
    },
    'favourites': {
      'nodata':
        "Non ci sono tracce preferite, clicca sull'icona cuore di una traccia per aggiungerla a questa lista",
      'title': 'Preferiti',
    },
    'home': {
      'button': 'Scegli un punto di partenza',
      'closerTracks': 'Percorsi nelle vicinanze',
      'intro': 'Pianifichiamo insieme la tua prossima avventura',
      'mostViewedTracks': 'Percorsi più visti',
      'nocloserTracks': 'Non sembrano esser presenti percorsi vicino alla posizione attuale',
      'welcome': 'Benvenuto!',
    },
    'itinerary': {
      'btnFullMapExpand': 'Espandi',
      'btnFullMapReduce': 'Riduci',
      'btndnavigate': 'Naviga',
      'btndownload': 'Scarica',
      'btnfavourite': 'Preferiti',
      'btnshare': 'Condividi',
      'description': {
        'description': 'Descrizione',
        'gallery': 'Gallery',
      },
      'detail': {
        'ascent': 'Dislivello +',
        'descent': 'Dislivello -',
        'distance': 'Lunghezza',
        'ele_from': 'Quota di partenza',
        'ele_max': 'Quota massima',
        'ele_min': 'Quota minima',
        'ele_to': 'Quota di arrivo',
      },
      'directions': 'Ottieni indicazioni',
      'download': 'Scarica',
      'downloadpanel': {
        'complete': 'Download completato',
        'dontclose': "Non chiudere l'applicazione durante la fase di installazione dei dati",
        'downdata': 'Download dei dati',
        'download': 'Download',
        'downloadbtn': 'Scarica',
        'downloading': 'Downloading',
        'downmap': 'Download della mappa',
        'downmedia': 'Download dei media',
        'downsetup': 'Setup iniziale',
        'gotobtn': 'Vai ai miei download',
        'install': 'Istallazione',
      },
      'favbtn': {
        'isFavourite': 'Aggiunto ai preferiti!',
        'isNotFavourite': 'Rimosso dai preferiti!',
      },
      'modalconfirm': {
        'cancel': 'Annulla',
        'confirm': 'Uscire',
        'text': 'Uscire senza terminare il download della traccia?',
        'title': 'Interrompere il download?',
      },
      'modalNotImpemented': {
        'text': 'Comming soon',
        'title': 'Attualmente non disponibile',
        'confirm': 'ok',
      },
      'navigate': 'Naviga',
      'share': 'Condividi',
      'tabdescription': 'Descrizione',
      'tabdetail': 'Dettagli',
      'tabeat': 'Dove mangiare',
      'tabhowto': 'Come arrivare',
      'tabviability': 'Percorribilità',
      'tabwalkable': 'Accessibilità',
      'viability': {
        'info': 'Altre info',
        'title': 'Praticabilità del percorso',
      },
    },
    'map': {
      'loading': 'ricerca in corso...',
      'recordingbtn': 'Reg. in corso',
      'searchherebtn': "Cerca in quest'area",
    },
    'photodetail': {
      'delete': 'Elimina registrazione',
      'edit': 'Modifica registrazione',
      'title': 'Foto registrata',
    },
    'photolist': {
      'noitem': 'Al momento non sono presenti foto registrate',
      'title': 'Foto registrate',
    },
    'profile': {
      'data': {
        'data': 'Utilizzo dati',
        'downloads': 'Downloads',
        'tracks': 'Tracce scaricate',
      },
      'loggedOut': {
        'login': 'Accedi',
        'signup': 'Registrati',
        'slides': {
          '0': 'Accedi e scarica i tuoi percorsi preferiti',
          '1': 'Puoi anche condividerli con i tuoi compagni di viaggio',
        },
      },
      'records': {
        'photos': 'Photos',
        'title': 'Registrazioni',
        'tracks': 'Tracce',
        'vocals': 'Note vocali',
        'waypoints': 'Waypoint',
      },
      'title': 'Profilo',
      'lang': 'lingua',
      'langPlaceholder': 'seleziona',
      'projectlink': 'Progetto',
      'disclaimerlink': 'Disclaimer',
      'creditslink': 'Credits',
      'privacylink': 'Privacy',
    },
    'register': {
      'averagespeed': 'Velocità media',
      'backgroundbtn': 'Registra in background',
      'currentspeed': 'Velocità attuale',
      'modalconfirm': {
        'cancel': 'Annulla',
        'confirm': 'Conferma',
        'text': 'Una volta confermato non potrai più riprendere questa navigazione',
        'title': 'Confermi di voler terminare la registrazione?',
      },
      'modalexit': {
        'cancel': 'Annulla',
        'confirm': 'Elimina',
        'text':
          'Completando l’azione la traccia verrà eliminata dal tuo profilo e da Webmapp. L’operazione non può essere annullata.',
        'title': 'Elimina per sempre?',
      },
      'modalphotos': {
        'deselectall': 'Deseleziona tutto',
        'noimages': 'Non sono presenti immagini scattate durante la registrazione del percorso',
        'savebtn': 'Carica',
        'selectall': 'Seleziona tutto',
        'title': 'Carica foto',
      },
      'modalsave': {
        'closemodal': {
          'back': 'Torna alla registrazione',
          'cancel': 'Annulla',
          'delete': 'Elimina registrazione',
        },
        'deletebtn': 'ESCI SENZA SALVARE',
        'formactivity': 'Tipo di attività',
        'formactivityerror': 'Il tipo di attività è un campo obbligatorio',
        'formactivityplaceholder': 'Seleziona',
        'formdescription': 'Descrizione',
        'formdescriptionplaceholder': 'Se vuoi puoi aggiungere una descrizione',
        'formphotos': 'Aggiungi foto scattate durante il percorso',
        'formtitle': 'Titolo traccia',
        'formtitleerror': 'Il campo titolo è obbligatorio',
        'formtitleplaceholder': 'Inserisci un titolo',
        'photoaction': 'Carica',
        'photoactionshort': 'Aggiungi',
        'photoadvice': 'max 3 photo',
        'photoadviceshort': '(max 3 photo)',
        'phototext': 'foto dalla galleria del telefono',
        'phototextshort': 'Foto',
        'savebtn': 'Salva',
        'title': 'Salva traccia',
        'titleEdit': 'Modifica traccia',
      },
      'odo': 'Km percorsi',
      'pausebtn': 'Pausa',
      'resumebtn': 'Riprendi',
      'stopbtn': 'Termina',
      'time': 'In movimento',
      'title': 'Registra una traccia',
    },
    'registeruser': {
      'cf': 'Codice Fiscale',
      'cfextra': 'Perché ti chiediamo il CF?',
      'cfph': 'XXXXXXXXXXXXXXXX',
      'cfpopovermessage':
        'Se sei Socia/o CAI inserisci il tuo CF al momento della registrazione. Per te il download delle tappe del Sentiero Italia CAI sarà automaticamente gratuito!',
      'cfpopovertitle': 'Perchè ti chiediamo il Codice Fiscale?',
      'confirmPassword': 'Conferma password',
      'confirmPasswordph': 'Ripeti password',
      'email': 'Email',
      'emailph': 'esempio@email.com',
      'errors': {
        'form': {
          'nameRequired': "E' necessario inserire un nome",
        },
        'form.cfInvalid': 'Il codice fiscale non è valido',
        'form.confirmPasswordInvalid': 'La conferma non corrisponde con la password inserita sopra',
        'form.confirmPasswordRequired': "E' necessario confermare la password",
        'form.emailInvalid': "L'email non è valida",
        'form.emailRequired': "E' necessario inserire un'email",
        'form.passwordRequired': "E' necessario inserire una password",
      },
      'genericError': "Errore durante la registrazione dell'utente",
      'loading': 'Registrazione in corso',
      'name': 'Nome',
      'nameph': 'inserisci il tuo nome',
      'password': 'Password',
      'passwordph': 'inserisci password',
      'privacy1': 'Cliccando su "Registrati" accetti la nostra ',
      'privacy2': 'e i',
      'privacy3': '',
      'privacylink1': 'Privacy Policy',
      'privacylink2': 'Termini e condizioni',
      'registerbutton': 'Registrati',
      'title': 'Unisciti alla community di webmapp!',
    },
    'route': {
      'btnFullMapExpand': 'Espandi',
      'btnFullMapReduce': 'Riduci',
      'btndnavigate': 'Naviga',
      'btndownload': 'Scarica',
      'btnfavourite': 'Preferiti',
      'btnshare': 'Condividi',
      'description': {
        'description': 'Descrizione',
        'gallery': 'Gallery',
      },
      'detail': {
        'from': 'Partenza',
        'to': 'Arrivo',
        'ascent': 'Dislivello +',
        'descent': 'Dislivello -',
        'distance': 'Lunghezza',
        'duration_forward': 'durata di andata',
        'duration_backward': 'durata di ritorno',
        'difficulty': 'Difficoltà',
        'cai_scale': 'Scala di difficoltà CAI',
        'ele_from': 'Quota di partenza',
        'ele_max': 'Quota massima',
        'ele_min': 'Quota minima',
        'ele_to': 'Quota di arrivo',
      },
      'directions': 'Ottieni indicazioni',
      'download': 'Scarica',
      'downloadpanel': {
        'complete': 'Download completato',
        'dontclose': "Non chiudere l'applicazione durante la fase di installazione dei dati",
        'downdata': 'Download dei dati',
        'download': 'Download',
        'downloadbtn': 'Scarica',
        'downloading': 'Downloading',
        'downmap': 'Download della mappa',
        'downmedia': 'Download dei media',
        'downsetup': 'Setup iniziale',
        'gotobtn': 'Vai ai miei download',
        'install': 'Istallazione',
      },
      'favbtn': {
        'isFavourite': 'Aggiunto ai preferiti!',
        'isNotFavourite': 'Rimosso dai preferiti!',
      },
      'modalconfirm': {
        'cancel': 'Annulla',
        'confirm': 'Uscire',
        'text': 'Uscire senza terminare il download della traccia?',
        'title': 'Interrompere il download?',
      },
      'navigate': 'Naviga',
      'share': 'Condividi',
      'tabdescription': 'Descrizione',
      'tabdetail': 'Dettagli',
      'tabeat': 'Dove mangiare',
      'tabhowto': 'Come arrivare',
      'tabviability': 'Percorribilità',
      'tabwalkable': 'Accessibilità',
      'viability': {
        'info': 'Altre info',
        'title': 'Praticabilità del percorso',
      },
    },
    'store': {
      'button1': '1 Singolo Coin',
      'button100': 'Pack 100 coins',
      'button20': ' Pack 20 Coins',
      'button50': 'Pack 50 coins',
      'main': 'Webmapp Coins',
      'text': 'Puoi acquistare un singolo Coin, oppure scegliere fra uno o più pacchetti!',
      'text2':
        'I coin servono per scaricare percorsi e contenuti offline, e accedere a numerosi vantaggi!',
      'title': 'Store',
    },
    'trackdetail': {
      'avgspeed': 'Velocità media',
      'delete': 'Elimina registrazione',
      'details': 'Dettagli',
      'distance': 'Km percorsi',
      'edit': 'Modifica registrazione',
      'photos': 'Foto',
      'slope': 'Dislivello',
      'time': 'Tempo',
      'title': 'Traccia registrata',
      'topspeed': 'Velocità massima',
    },
    'tracklist': {
      'noitem': 'Al momento non sono presenti tracce registrate',
      'title': 'Tracce registrate',
    },
    'waypoint': {
      'here': 'Ti trovi qui',
      'modalsave': {
        'formdescription': 'Descrizione',
        'formdescriptionplaceholder': 'Se vuoi puoi aggiungere una descrizione',
        'formoptional': 'Facoltativo',
        'formphotos': 'Aggiungi foto del Waypoint',
        'formtitle': 'Titolo waypoint',
        'formtitleerror': 'Titolo waypont richiesto',
        'formtitleplaceholder': 'Inserisci un titolo',
        'formtype': 'Tipo di Waypoint',
        'formtypeerror': 'Tipo del waypoint richiesto',
        'formtypeplaceholder': 'Seleziona',
        'photoaction': 'Carica',
        'photoactionshort': 'Aggiungi',
        'photoadvice': 'max 3 photo',
        'photoadviceshort': '(max 3 photo)',
        'phototext': 'foto dalla galleria del telefono',
        'phototextshort': 'Foto',
        'save': 'Salva ',
        'title': 'Registra un waypoint',
      },
      'save': 'Salva waypoint',
      'title': 'Registra un waypoint',
    },
    'waypointdetail': {
      'delete': 'Elimina registrazione',
      'edit': 'Modifica registrazione',
      'title': 'Waypoint registrato',
    },
    'waypointlist': {
      'noitem': 'Al momento non sono presenti waypoint registrati',
      'title': 'Waypoint registrati',
    },
    'project': {
      'title': 'Il Progetto',
    },
    'credits': {
      'title': 'Credits',
      'webmappContent1':
        'La app {{appName}} è sviluppata e mantenuta da Webmapp.<br> Webmapp realizza servizi cartografici web, app mobile, e cartografia stampata per il Turismo Natura & Avventura.<br>Per maggiori informazioni visitate il nostro sito web ',
      'webmappContent2':
        ' o scriveteci all\'indirizzo <a href="mailto:info@webmapp.it">info@webmapp.it</a>',
      'map': 'Mappa',
      'mapContent': '© Webmapp, distribuita con licenza CC BY-NC-SA',
      'cartographicData': 'Dati cartografici',
      'cartographicDataContent': '© OpenStreetMap Contributors',
    },
    'disclaimer': {
      'title': 'Disclaimer',
      'content':
        "L'escursionismo e, più in generale, l'attività all'aria aperta, è una attività potenzialmente rischiosa: prima di avventurarti in una escursione assicurati di avere le conoscenze e le competenze per farlo. Se non sei sicuro rivolgiti agli esperti locali che ti possono aiutare, suggerire e supportare nella pianificazione e nello svolgimento delle tue attività. I dati presentati su questa APP non possono garantire completamente la percorribilità senza rischi del percorso: potrebbero essersi verificati cambiamenti, anche importanti, dall’ultima verifica effettuata del percorso stesso. E’ fondamentale quindi che chi si appresta a svolgere attività valuti attentamente l’opportunità di proseguire in base ai suggerimenti e ai consigli contenuti in questa APP, in base alla propria esperienza, alle condizioni metereologiche (anche dei giorni precedenti) e di una valutazione effettuata sul campo all’inizio dello svolgimento della attività. La società Webmapp S.r.l. non fornisce garanzie sulla sicurezza dei luoghi descritti, e non si assume alcuna responsabilità per eventuali danni causati dallo svolgimento delle attività descritte.",
    },
  },
  'services': {
    'geolocation': {
      'notification': {
        'text': {
          'newTrackRecord': "Tocca la notifica per aprire l'applicazione",
        },
        'title': {
          'newTrackRecord': 'Registrazione in corso',
        },
      },
    },
    'share': {
      'dialogTitle': 'Condividi con i tuoi amici',
      'text': 'Ecco un percorso interessante di webmapp',
      'title': 'Hai visto questo percorso?',
      'url': 'www.webmapp.it',
    },
  },
  'tabs': {
    'favourites': 'preferiti',
    'home': 'home',
    'map': 'mappa',
    'profile': 'profilo',
  },
  'no-tracks': 'Non hai ancora scaricato nessuna traccia',
  'hiking': 'Trekking',
  'skitouring': 'Scialpinismo',
  'walking': 'Passeggiata',
  'running': 'Corsa',
  'asphalt': 'Asfalto',
  'bitumenduro': 'Bitumenduro',
  'onoff': 'Onoff',
  'real_dirt': 'Sterrato vero',
  'bar': 'Bar',
  'cycling': 'In bicicletta',
  'poi_type': 'punti di interesse',
  'where': 'luoghi',
  'I tuoi dati': 'I tuoi dati',
  'waypoint': 'Segnavia',
  'landslide': 'Frana',
  'sightseen': 'Panorama',
  'signage': 'Segnaletica',
  'fallen-tree': 'Albero caduto',
  'interrupted-passage': 'Passaggio interrotto',
  'missing-signage': 'Segnaletica mancante',
  'mountain_passes': 'Passi di montagna',
  'roads_to_drive': 'Strade da guidare (BLU)',
  'points_of_interest': 'Punti di interesse (VERDE)',
  'dirt_roads': 'Strade sterrate (ARANCIO)',
  'streets_to_admire': 'Strutture ricettive (ROSA)',
  'other': 'Altri tipi ti luoghi di interesse',
  'description': 'Descrizione',
  'share_ugcpoi': 'Vuoi proporre il tuo waypoint a motomappa?',
  'no': 'No',
  'yes': 'Si',
  'duration': 'Durata',
  'Hai salvato correttamente il tuo waypoint. Puoi visualizzarlo accedendo al tuo profilo.':
    'Hai salvato correttamente il tuo waypoint. Puoi visualizzarlo accedendo al tuo profilo.',
  'Elimina account': 'Elimina account',
  'Vuoi veramente eliminare il tuo account? È obbligatorio scrivere "elimina account" per procedere.':
    'Vuoi veramente eliminare il tuo account? È obbligatorio scrivere "elimina account" per procedere.',
  'Digita "elimina account"': 'Digita "elimina account"',
  'La conferma non corrisponde. Digita "elimina account" per procedere.':
    'La conferma non corrisponde. Digita "elimina account" per procedere.',
  'Azione irreversibile': 'Azione irreversibile',
  'Attenzione': 'Attenzione',
  'Annulla': 'Annulla',
  'Conferma': 'Conferma',
  'Link utili': 'Link utili',
  "Per registrare tracce e poi correttamente, abilita l'autorizzazione alla posizione nelle impostazioni":
    "Per registrare tracce e poi correttamente, abilita l'autorizzazione alla posizione nelle impostazioni",
  'Apri impostazioni App': 'Apri impostazioni App',
};
