export const wmSQ = {
  'skitouring': 'Skitouring',
  'horse': 'Kalërim',
  'walking': 'Shëtitje',
  'running': 'Vrap',
  'asphalt': 'Asfalt',
  'bitumenduro': 'Bitumenduro',
  'onoff': 'OnOff',
  'real_dirt': 'Terren i vërtetë',
  'bar': 'Bar',
  'cycling': 'Çiklizëm',
  'poi_type': 'Pikat e interesit',
  'where': 'Vendndodhjet',
  'lang': 'Gjuha',
  'langPlaceholder': 'Zgjidh',
  'from': 'Nisja',
  'to': 'Mbërritja',
  'ascent': 'Ngjitje',
  'descent': 'Zbritje',
  'distance': 'Distanca',
  'ele_from': 'Lartësia e nisjes',
  'ele_max': 'Lartësia maksimale',
  'ele_min': 'Lartësia minimale',
  'ele_to': 'Lartësia e mbërritjes',
  'difficulty': 'Vështirësia',
  'cai_scale': 'Shkalla e vështirësisë CAI',
  'duration_forward': 'Kohëzgjatja e vajtjes',
  'duration_backward': 'Kohëzgjatja e kthimit',
  'Filtri': 'Filtrat',
  'Cerca': 'Kërko',
  'tracce': 'Gjurmët',
  'hiking': 'Ecje në natyrë',
  'Escursionismo': 'Ecje në natyrë',
  'escursionismo': 'Ecje në natyrë',
  'i miei percorsi': 'Krijo itinerar',
  'metri': 'Metra',
  'Vedi su OpenStreetMap': 'Shiko në OpenStreetMap',
  'Descrizione Audio': 'Përshkrim Audio',
  'il tuo browser non supporta gli elementi audio': 'Shfletuesi juaj nuk mbështet elementet audio.',
  'stampa': 'Printo',
  'Difficoltà': 'Vështirësia',
  'Scala di difficoltà CAI': 'Shkalla e vështirësisë CAI',
  'Questo percorso non è accessibile': 'Ky itinerar nuk është i aksesueshëm',
  'Descrizione': 'Përshkrimi',
  'Punto di partenza': 'Pika e nisjes',
  'Punto di arrivo': 'Pika e mbërritjes',
  'Dettagli tecnici': 'Detajet teknike',
  'Attività': 'Aktivitetet',
  'Come arrivare': 'Si të arrini',
  'Bici muscolari': 'Biçikleta normale',
  'Bici elettriche': 'Biçikleta elektrike',
  'Mezzi pubblici': 'Transporti publik',
  'Auto Propria': 'Makina personale',
  'Punti di interesse': 'Pikat e interesit',
  'Collegamenti esterni': 'Lidhje të jashtme',
  'Downloads': 'Shkarkime',
  'edit geohub': 'Modifiko Geohub',
  'NCC': 'NCC',
  'Filtri attivi': 'Filtrat aktivë',
  'Reset filtri': 'Rivendos filtrat',
  'Torna alla home': 'Kthehu në faqen kryesore',
  'Risultati': 'Rezultatet',
  'Caricamento in corso.': 'Po ngarkohet.',
  'Spiacenti non ci sono risultati con questi criteri di ricerca.':
    'Më vjen keq, nuk ka rezultate për këto kritere kërkimi.',
  'duration': 'Kohëzgjatja',
  'Bentornato!': 'Mirë se u ktheve!',
  'Accedi con le tue credenziali per continuare.': 'Hyni me kredencialet tuaja për të vazhduar.',
  'E-mail': 'E-mail',
  "L'e-mail è obbligatoria": 'E-mail-i është i detyrueshëm',
  "Hai inserito un'e-mail non valida": 'Keni futur një e-mail të pavlefshëm',
  'Password': 'Fjalëkalimi',
  'La password è obbligatoria': 'Fjalëkalimi është i detyrueshëm',
  'Accedi': 'Hyr',
  'Hai dimenticato la password?': 'Keni harruar fjalëkalimin?',
  'Accedi e scarica i tuoi percorsi preferiti': 'Hyni dhe shkarkoni itineraret tuaj të preferuar',
  'Puoi anche condividerli con i tuoi compagni di viaggio':
    'Mund t’i ndani edhe me shokët tuaj të udhëtimit',
  'Registrati': 'Regjistrohu',
  'Tracce scaricate': 'Gjurmët e shkarkuara',
  'Utilizzo dati': 'Përdorimi i të dhënave',
  'Registrazioni': 'Regjistrime',
  'Photos': 'Foto',
  'Waypoint': 'Pika ndalese',
  'elimina': 'fshij',
  'Nome': 'Emri',
  'inserisci il tuo nome': 'Shkruani emrin tuaj',
  "E' necessario inserire un nome": 'Duhet të shkruani një emër',
  'Email': 'Email',
  "E' necessario inserire una email": 'Duhet të shkruani një email',
  "L'email inserita non è un'email valida": 'Email-i i futur nuk është i vlefshëm',
  'Codice Fiscale': 'Kodi Fiskal',
  'Perché ti chiediamo il CF?': 'Pse ju kërkojmë Kodin Fiskal?',
  'Il codice fiscale inserito non è valido': 'Kodi Fiskal i futur nuk është i vlefshëm',
  'inserisci password': 'Shkruani fjalëkalimin',
  "E' necessario inserire la password": 'Duhet të shkruani fjalëkalimin',
  'Conferma password': 'Konfirmo fjalëkalimin',
  'Ripeti password': 'Përsërit fjalëkalimin',
  "E' necessario confermare la password": 'Duhet të konfirmoni fjalëkalimin',
  'La conferma non corrisponde con la password inserita sopra':
    'Konfirmimi nuk përputhet me fjalëkalimin e futur më sipër',
  'Cliccando su "Registrati" accetti la nostra': 'Duke klikuar "Regjistrohu", pranoni tonën',
  'e i': 'dhe',
  'Termini e condizioni': 'Kushtet dhe termat',
  "Errore durante la registrazione dell'utente": 'Gabim gjatë regjistrimit të përdoruesit',
  'Registrazione in corso': 'Regjistrimi është në proces',
  'Perchè ti chiediamo il Codice Fiscale?': 'Pse ju kërkojmë Kodin Fiskal?',
  'Se sei Socia/o CAI inserisci il tuo CF al momento della registrazione. Per te il download delle tappe del Sentiero Italia CAI sarà automaticamente gratuito!':
    'Nëse jeni anëtar/e i CAI, shkruani Kodin Fiskal gjatë regjistrimit. Për ju, shkarkimi i etapave të Sentiero Italia CAI do të jetë automatikisht falas!',
  "Origine dell'immagine": 'Origjina e imazhit',
  'Annulla': 'Anulo',
  'Dalla libreria': 'Nga biblioteka',
  'Scatta una foto': 'Bëj një foto',
  'placeholder': 'Kërko',
  'Carica File': 'Ngarko skedarin',
  'Trascina qui il tuo file o clicca per selezionare':
    'Tërhiqni skedarin këtu ose klikoni për të zgjedhur',
  'Accetta i seguenti formati': 'Pranon formatet e mëposhtme',
  'Tipo di file non supportato': 'Lloji i skedarit nuk mbështetet',
  'Il file non contiene una geometria valida': 'Skedari nuk përmban një gjeometri të vlefshme',
  'Errore durante la lettura del file, riprova': 'Gabim gjatë leximit të skedarit, provo përsëri',
  'Si è verificato un errore durante il salvataggio della traccia. Riprova':
    'Ndodhi një gabim gjatë ruajtjes së gjurmës. Provo përsëri',
  'La traccia è stata salvata correttamente': 'Gjurmët u ruajtën me sukses',
  'Scarica il tracciato GPX': 'Shkarko gjurmën GPX',
  'Scarica il tracciato KML': 'Shkarko gjurmën KML',
  'Scarica il tracciato GEOJSON': 'Shkarko gjurmën GEOJSON',
  'Link utili': 'Lidhje të dobishme',
  'apri pdf': 'Hap PDF',
  'pendenza': 'Pjerrësia',
  'Galleria': 'Galeria',
  'resetta la memoria': 'Rivendos memorien',
  'rimani acceso': 'Qëndro i ndezur',
  'forza il dispositivo a non spegnersi': 'Detyro pajisjen të mos fiket',
  'PAGINE': 'FAQE',
  'I tuoi dati': 'Të dhënat e tua',
  'pages.profile.projectlink': 'Projekti',
  'pages.profile.disclaimerlink': 'Mohim përgjegjësie',
  'pages.profile.creditslink': 'Kreditë',
  'pages.profile.privacylink': 'Privatësia',
  'pages.profile.title': 'Profili',
  'MEMORIA': 'MEMORIA',
  'la distanza espressa in metri che indica la cadenza di rilevamento posizione, più il distance filter è minore piu il consumo della batteria sarà maggiore.':
    'Distanca e shprehur në metra që tregon frekuencën e zbulimit të pozicionit, sa më i vogël të jetë filtri i distancës, aq më i madh do të jetë konsumi i baterisë.',
  "Per registrare tracce e poi correttamente, abilita l'autorizzazione alla posizione nelle impostazioni":
    'Për të regjistruar gjurmët dhe pikat e interesit saktësisht, aktivizo lejen e vendndodhjes në cilësimet.',
  'I miei percorsi': 'Itineraret e mia',
  'Sentieri': 'Shtigjet',
  '<span class="green">Livello 1: tratti non interessati dall\'alta quota (quota minore di {{orange}} metri)</span>':
    '<span class="green">Niveli 1: seksione që nuk preken nga lartësia e madhe (lartësia më e ulët se {{orange}} metra)</span>',
  '<span class="orange">Livello 2: tratti parzialmente in alta quota (quota compresa tra {{orange}} metri e {{red}} metri)</span>':
    '<span class="orange">Niveli 2: seksione që nuk preken nga lartësia e madhe (lartësia midis {{orange}} metra dhe {{red}} metra)</span>',
  '<span class="red">Livello 3: in alta quota (quota superiore {{red}} metri)</span>':
    '<span class="red">Niveli 3: seksione që preken nga lartësia e madhe (lartësia më e madhe se {{red}} metra)</span>',
  'Un utente è già stato registrato con questa email.': 'Një përdorues është tashmë regjistruar me këtë email.',
  'L\'email inserita non è corretta. Per favore, riprova.': 'Email-i që keni futur nuk është e saktë. Ju lutem provoni përsëri.',
  'La password inserita non è corretta. Per favore, riprova.': 'Fjalëkalimi që keni futur nuk është i saktë. Ju lutem provoni përsëri.',
  'Errore': 'Gabim',
};
