// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      wm-ugc-synchronized-badge {
        ion-icon {
          width: 22px;
          height: 22px;
        }
      }
    `, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/shared/ugc-synchronized-badge/ugc-synchronized-badge.component.ts"],"names":[],"mappings":";MACM;QACE;UACE,WAAW;UACX,YAAY;QACd;MACF","sourcesContent":["\n      wm-ugc-synchronized-badge {\n        ion-icon {\n          width: 22px;\n          height: 22px;\n        }\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
