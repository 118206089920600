// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-modal-ugc-track-uploader ion-content {
  --padding-top: 0;
  --padding-bottom: 0;
}
wm-modal-ugc-track-uploader ion-content wm-map {
  height: 350px;
  width: 100%;
}
wm-modal-ugc-track-uploader ion-grid {
  height: 100%;
  padding: 0;
}
wm-modal-ugc-track-uploader ion-row {
  height: 100%;
}
wm-modal-ugc-track-uploader ion-col {
  height: 100%;
  display: flex;
  flex-direction: column;
}
wm-modal-ugc-track-uploader ion-card {
  flex: 1;
  margin: 0;
  height: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
wm-modal-ugc-track-uploader ion-card.ion-activated {
  background-color: var(--ion-color-light);
}
wm-modal-ugc-track-uploader ion-icon[name=cloud-upload] {
  font-size: 48px;
  margin-bottom: 16px;
}
wm-modal-ugc-track-uploader ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/modal-ugc-track-uploader/modal-ugc-track-uploader.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,mBAAA;AAAJ;AACI;EACE,aAAA;EACA,WAAA;AACN;AAGE;EACE,YAAA;EACA,UAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;AAHJ;AAME;EACE,OAAA;EACA,SAAA;EACA,YAAA;EACA,eAAA;EACA,sCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAJJ;AAMI;EACE,wCAAA;AAJN;AAQE;EACE,eAAA;EACA,mBAAA;AANJ;AASE;EACE,qBAAA;EACA,mBAAA;AAPJ","sourcesContent":["wm-modal-ugc-track-uploader {\n  ion-content {\n    --padding-top: 0;\n    --padding-bottom: 0;\n    wm-map {\n      height: 350px;\n      width: 100%;\n    }\n  }\n\n  ion-grid {\n    height: 100%;\n    padding: 0;\n  }\n\n  ion-row {\n    height: 100%;\n  }\n\n  ion-col {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n  }\n\n  ion-card {\n    flex: 1;\n    margin: 0;\n    height: 100%;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    &.ion-activated {\n      background-color: var(--ion-color-light);\n    }\n  }\n\n  ion-icon[name='cloud-upload'] {\n    font-size: 48px;\n    margin-bottom: 16px;\n  }\n\n  ion-item {\n    --padding-start: 16px;\n    --padding-end: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
