// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-modal-image {
  --background: white;
  --color: black;
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom);
  height: calc(100% - constant(safe-area-inset-top));
  height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}
wm-modal-image .gallery {
  height: 100%;
}
wm-modal-image .gallery ion-slide {
  width: 100%;
}
wm-modal-image .gallery ion-slide wm-img {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  transition: all 1s;
}
wm-modal-image .gallery ion-slide wm-img img {
  object-fit: contain;
}

ion-modal {
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal::part(backdrop) {
  opacity: 1;
}

ion-slide {
  background-color: white;
}

ion-fab-button {
  --background: white;
  --color: black;
  top: calc(constant(safe-area-inset-top));
  top: calc(env(safe-area-inset-top));
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
  ion-modal {
    --width: 700px;
    --height: 700px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/modal-image/modal-image.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,cAAA;EACA,yCAAA;EACA,oCAAA;EACA,0CAAA;EACA,kDAAA;EACA,2EAAA;AACF;AACE;EACE,YAAA;AACJ;AAAI;EACE,WAAA;AAEN;AADM;EACE,8BAAA;EACA,6BAAA;EACA,kBAAA;AAGR;AAFQ;EACE,mBAAA;AAIV;;AAEA;EACE,qBAAA;EACA,gFAAA;AACF;;AAEA;EACE,UAAA;AACF;;AACA;EACE,uBAAA;AAEF;;AAAA;EACE,mBAAA;EACA,cAAA;EACA,wCAAA;EACA,mCAAA;AAGF;;AAAA;EACE;IACE,cAAA;IACA,eAAA;EAGF;AACF","sourcesContent":["wm-modal-image {\n  --background: white;\n  --color: black;\n  margin-top: constant(safe-area-inset-top); // for ios 11.1\n  margin-top: env(safe-area-inset-top); // for ios 11.2 and onwards\n  margin-bottom: env(safe-area-inset-bottom);\n  height: calc(100% - constant(safe-area-inset-top));\n  height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));\n\n  .gallery {\n    height: 100%;\n    ion-slide {\n      width: 100%;\n      wm-img {\n        height: -webkit-fill-available;\n        width: -webkit-fill-available;\n        transition: all 1s;\n        img {\n          object-fit: contain;\n        }\n      }\n    }\n  }\n}\nion-modal {\n  --border-radius: 16px;\n  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);\n}\n\nion-modal::part(backdrop) {\n  opacity: 1;\n}\nion-slide {\n  background-color: white;\n}\nion-fab-button {\n  --background: white;\n  --color: black;\n  top: calc(constant(safe-area-inset-top));\n  top: calc(env(safe-area-inset-top));\n}\n\n@media only screen and (min-width: 768px) and (min-height: 768px) {\n  ion-modal {\n    --width: 700px;\n    --height: 700px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
