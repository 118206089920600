// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-image-gallery {
  position: relative;
}
wm-image-gallery .webmapp-pagepoi-info-body-photoslider {
  padding: 10px 0;
}
wm-image-gallery .webmapp-pagepoi-info-body-photoslider .webmapp-pagepoi-info-body-photo {
  height: 150px !important;
  width: 250px !important;
}
wm-image-gallery .webmapp-pagepoi-info-body-photoslider .webmapp-pagepoi-info-body-photo img {
  height: 150px !important;
  width: 250px !important;
}
wm-image-gallery .webmapp-pagepoi-info-body-photoslider .webmapp-pagepoi-info-body-photo::part(image) {
  border-radius: 15px;
}
wm-image-gallery ion-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  width: 45px;
  height: 45px;
  --border-radius: 100%;
  --background: #f0f0f0;
  --color: black;
}
wm-image-gallery ion-button.left {
  margin-left: 15px;
}
wm-image-gallery ion-button.right {
  right: 0;
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/image-gallery/image-gallery.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,eAAA;AAEJ;AADI;EACE,wBAAA;EACA,uBAAA;AAGN;AAFM;EACE,wBAAA;EACA,uBAAA;AAIR;AAFM;EACE,mBAAA;AAIR;AAAE;EACE,kBAAA;EACA,QAAA;EACA,2BAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;EACA,qBAAA;EACA,cAAA;AAEJ;AAAI;EACE,iBAAA;AAEN;AAAI;EACE,QAAA;EACA,kBAAA;AAEN","sourcesContent":["wm-image-gallery {\n  position: relative;\n  .webmapp-pagepoi-info-body-photoslider {\n    padding: 10px 0;\n    .webmapp-pagepoi-info-body-photo {\n      height: 150px !important;\n      width: 250px !important;\n      img {\n        height: 150px !important;\n        width: 250px !important;\n      }\n      &::part(image) {\n        border-radius: 15px;\n      }\n    }\n  }\n  ion-button {\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    z-index: 99;\n    width: 45px;\n    height: 45px;\n    --border-radius: 100%;\n    --background: #f0f0f0;\n    --color: black;\n\n    &.left {\n      margin-left: 15px;\n    }\n    &.right {\n      right: 0;\n      margin-right: 15px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
