// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-map-popover ion-card {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 400px;
  z-index: 1;
}
wm-map-popover .red {
  color: red;
}
wm-map-popover .orange {
  color: orange;
}
wm-map-popover .green {
  color: green;
}
wm-map-popover .draw-path-alert {
  left: calc(50% + 200px);
  transform: translateX(-50%);
  height: 40px;
  min-width: unset;
  --background: antiquewhite;
  --color: black;
}
wm-map-popover .draw-path-alert ion-card-content {
  line-height: 16px;
}
wm-map-popover .draw-path-alert .message {
  font-size: 16px;
}
wm-map-popover .draw-path-alert a {
  margin-left: 10px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/map-core/src/components/popover/popover.map.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,gBAAA;EACA,UAAA;AAAJ;AAEE;EACE,UAAA;AAAJ;AAEE;EACE,aAAA;AAAJ;AAEE;EACE,YAAA;AAAJ;AAEE;EACE,uBAAA;EACA,2BAAA;EACA,YAAA;EACA,gBAAA;EACA,0BAAA;EACA,cAAA;AAAJ;AACI;EACE,iBAAA;AACN;AACI;EACE,eAAA;AACN;AACI;EACE,iBAAA;EACA,eAAA;AACN","sourcesContent":["wm-map-popover {\n  ion-card {\n    position: absolute;\n    top: 0;\n    left: 0;\n    min-width: 400px;\n    z-index: 1;\n  }\n  .red {\n    color: red;\n  }\n  .orange {\n    color: orange;\n  }\n  .green {\n    color: green;\n  }\n  .draw-path-alert {\n    left: calc(50% + 200px);\n    transform: translateX(-50%);\n    height: 40px;\n    min-width: unset;\n    --background: antiquewhite;\n    --color: black;\n    ion-card-content {\n      line-height: 16px;\n    }\n    .message {\n      font-size: 16px;\n    }\n    a {\n      margin-left: 10px;\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
