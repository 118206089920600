// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-feature-useful-urls {
  display: block;
  margin: var(--wm-feature-details-margin);
}
wm-feature-useful-urls .wm-feature-useful-urls-item .wm-feature-useful-urls-item-label {
  font-family: var(--wm-font-family-content);
}
wm-feature-useful-urls .wm-feature-useful-urls-item ion-icon {
  color: var(--wm-color-primary);
}
wm-feature-useful-urls .webmapp-title {
  font-weight: var(--wm-feature-details-title-font-weight);
  font-size: var(--wm-feature-details-title-font-size);
  color: var(--wm-feature-details-title-color);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/feature-useful-urls/feature-useful-urls.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,wCAAA;AACF;AACI;EACE,0CAAA;AACN;AACI;EACE,8BAAA;AACN;AAEE;EACE,wDAAA;EACA,oDAAA;EACA,4CAAA;AAAJ","sourcesContent":["wm-feature-useful-urls {\n  display: block;\n  margin: var(--wm-feature-details-margin);\n  .wm-feature-useful-urls-item {\n    .wm-feature-useful-urls-item-label {\n      font-family: var(--wm-font-family-content);\n    }\n    ion-icon {\n      color: var(--wm-color-primary);\n    }\n  }\n  .webmapp-title {\n    font-weight: var(--wm-feature-details-title-font-weight);\n    font-size: var(--wm-feature-details-title-font-size);\n    color: var(--wm-feature-details-title-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
