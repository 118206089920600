// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-ugc-medias {
  position: relative;
  display: block;
  margin: var(--wm-feature-details-margin);
}
wm-ugc-medias:empty {
  margin: 0;
}
wm-ugc-medias .wm-ugc-category-title {
  font-weight: var(--wm-feature-details-title-font-weight);
  font-size: var(--wm-feature-details-title-font-size);
  color: var(--wm-feature-details-title-color);
}
wm-ugc-medias ion-slides {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  width: -webkit-fill-available !important;
  position: relative;
  background-color: white !important;
}
wm-ugc-medias ion-slides ion-slide {
  max-height: 300px;
  overflow: hidden;
  border-radius: 15px;
  position: relative;
}
wm-ugc-medias ion-button {
  --background: var(--wm-color-light);
  --color: var(--wm-color-dark);
  --border-radius: 50%;
  --padding-start: 12px;
  --padding-end: 12px;
  width: 40px;
  height: 40px;
  z-index: 999;
  opacity: 0.8;
}
wm-ugc-medias ion-button.left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 5px;
}
wm-ugc-medias ion-button.right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/ugc-medias/wm-ugc-medias.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,wCAAA;AACF;AAAE;EACE,SAAA;AAEJ;AACE;EACE,wDAAA;EACA,oDAAA;EACA,4CAAA;AACJ;AAEE;EACE,2BAAA;EACA,8BAAA;EACA,wCAAA;EACA,kBAAA;EACA,kCAAA;AAAJ;AACI;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AACN;AAGE;EACE,mCAAA;EACA,6BAAA;EACA,oBAAA;EACA,qBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;AADJ;AAGI;EACE,kBAAA;EACA,OAAA;EACA,QAAA;EACA,2BAAA;EACA,gBAAA;AADN;AAII;EACE,kBAAA;EACA,QAAA;EACA,QAAA;EACA,2BAAA;EACA,iBAAA;AAFN","sourcesContent":["wm-ugc-medias {\n  position: relative;\n  display: block;\n  margin: var(--wm-feature-details-margin);\n  &:empty {\n    margin: 0;\n  }\n\n  .wm-ugc-category-title{\n    font-weight: var(--wm-feature-details-title-font-weight);\n    font-size: var(--wm-feature-details-title-font-size);\n    color: var(--wm-feature-details-title-color);\n  }\n\n  ion-slides {\n    margin-top: 16px !important;\n    margin-bottom: 16px !important;\n    width: -webkit-fill-available !important;\n    position: relative;\n    background-color: white !important;\n    ion-slide {\n      max-height: 300px;\n      overflow: hidden;\n      border-radius: 15px;\n      position: relative;\n    }\n  }\n\n  ion-button {\n    --background: var(--wm-color-light);\n    --color: var(--wm-color-dark);\n    --border-radius: 50%;\n    --padding-start: 12px;\n    --padding-end: 12px;\n    width: 40px;\n    height: 40px;\n    z-index: 999;\n    opacity: 0.8;\n\n    &.left {\n      position: absolute;\n      left: 0;\n      top: 50%;\n      transform: translateY(-50%);\n      margin-left: 5px;\n    }\n\n    &.right {\n      position: absolute;\n      right: 0;\n      top: 50%;\n      transform: translateY(-50%);\n      margin-right: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
