// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-track-audio {
  display: block;
  margin: var(--wm-feature-details-margin);
}
wm-track-audio ion-title {
  font-size: var(--wm-feature-details-title-font-size);
  font-weight: var(--wm-feature-details-title-font-weight);
  color: var(--wm-feature-details-title-color);
}
wm-track-audio audio {
  width: -webkit-fill-available;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/track-audio/track-audio.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,wCAAA;AACF;AAAE;EACE,oDAAA;EACA,wDAAA;EACA,4CAAA;AAEJ;AAAE;EACE,6BAAA;AAEJ","sourcesContent":["wm-track-audio {\n  display: block;\n  margin: var(--wm-feature-details-margin);\n  ion-title {\n    font-size: var(--wm-feature-details-title-font-size);\n    font-weight: var(--wm-feature-details-title-font-weight);\n    color: var(--wm-feature-details-title-color);\n  }\n  audio {\n    width: -webkit-fill-available;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
