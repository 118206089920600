// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-track-alert {
  padding-left: 20px;
  margin-bottom: 10px;
  order: -1;
  color: #ff0000;
  display: flex;
  flex-direction: row;
  align-items: center;
}
wm-track-alert ion-icon {
  font-size: 25px;
  align-self: center;
  margin: 2px;
  width: 90px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/track-alert/track-alert.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,SAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACE,eAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;AACJ","sourcesContent":["wm-track-alert {\n  padding-left: 20px;\n  margin-bottom: 10px;\n  order: -1;\n  color: #ff0000;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  ion-icon {\n    font-size: 25px;\n    align-self: center;\n    margin: 2px;\n    width: 90px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
