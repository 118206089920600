import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription, combineLatest, from} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';
import {ecTracksLoading, poisInitCount} from '@wm-core/store/features/ec/ec.selector';

import {
  downloadsOpened,
  ecLayer,
  lastFilterType,
  showTracks,
  ugcOpened,
} from '@wm-core/store/user-activity/user-activity.selector';
import {
  countAll,
  countPois,
  countTracks,
  pois,
  tracks,
} from '@wm-core/store/features/features.selector';
import {WmFeature} from '@wm-types/feature';
import {Point} from 'geojson';
import {IHIT} from '@wm-core/types/elastic';
import {getEcTracks, removeEcTrack} from '@wm-core/utils/localForage';
import {LangService} from '@wm-core/localization/lang.service';
import {AlertController} from '@ionic/angular';

@Component({
  selector: 'wm-home-result',
  templateUrl: './home-result.component.html',
  styleUrls: ['./home-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class WmHomeResultComponent implements OnDestroy {
  private _resultTypeSub$: Subscription = Subscription.EMPTY;

  @Output() poiEVT: EventEmitter<number | string> = new EventEmitter();
  @Output() refreshDownloads: EventEmitter<void> = new EventEmitter<void>();
  @Output() trackEVT: EventEmitter<number | string> = new EventEmitter();

  countAll$ = this._store.select(countAll);
  countInitPois$ = this._store.select(poisInitCount);
  countPois$: Observable<number> = this._store.select(countPois);
  countTracks$: Observable<number> = this._store.select(countTracks);
  currentLayer$ = this._store.select(ecLayer);
  downaloadOpened$ = this._store.select(downloadsOpened);
  downloadsTracks$ = this.refreshDownloads.pipe(
    startWith(undefined), // Emetti un valore iniziale per avviare il flusso
    switchMap(() =>
      from(getEcTracks()).pipe(map(t => t.map(track => track.properties as unknown as IHIT))),
    ),
  );
  ectracks$ = this._store.select(tracks);
  lastFilterType$ = this._store.select(lastFilterType);
  pois$: Observable<WmFeature<Point>[]> = this._store.select(pois);
  showResultType$: BehaviorSubject<string> = new BehaviorSubject<string>('tracks');
  showTracks$ = this._store.select(showTracks);
  tracks$: Observable<IHIT[]>;
  tracksLoading$: Observable<boolean> = this._store.select(ecTracksLoading);
  ugcOpened$: Observable<boolean> = this._store.select(ugcOpened);

  constructor(
    private _store: Store,
    private _langSvc: LangService,
    private _alertCtrl: AlertController,
  ) {
    this.tracks$ = combineLatest([
      this.ectracks$,
      this.downloadsTracks$,
      this.downaloadOpened$,
    ]).pipe(
      map(([ectracks, downloadsTracks, downloadsOpened]) => {
        if (downloadsOpened) {
          return downloadsTracks;
        }
        return ectracks;
      }),
    );
    this._resultTypeSub$ = combineLatest([
      this.countTracks$,
      this.countPois$,
      this.countInitPois$,
      this.lastFilterType$,
    ])
      .pipe(
        map(([tracks, pois, initPois, lastFilterType]) => {
          if (
            lastFilterType != null &&
            lastFilterType === 'pois' &&
            pois != null &&
            pois > 0 &&
            pois != null &&
            pois < initPois
          ) {
            return 'pois';
          } else if (tracks != null && tracks > 0) {
            return 'tracks';
          } else {
            return 'pois';
          }
        }),
      )
      .subscribe(value => {
        this.showResultType$.next(value);
      });
  }

  ngOnDestroy(): void {
    this._resultTypeSub$.unsubscribe();
  }

  changeResultType(event): void {
    this.showResultType$.next(event.target.value);
  }

  async removeDownloads(event: Event, id: string): Promise<void> {
    event.stopPropagation();
    const alert = await this._alertCtrl.create({
      header: this._langSvc.instant('Attenzione'),
      message: this._langSvc.instant('Sei sicuro di voler eliminare la traccia?'),
      buttons: [
        this._langSvc.instant('Annulla'),
        {
          text: this._langSvc.instant('Elimina'),
          handler: async () => {
            await removeEcTrack(`${id}`);
            this.refreshDownloads.emit();
          },
        },
      ],
    });
    alert.present().then();
  }

  setPoi(f: WmFeature<Point>): void {
    const id = f?.properties?.id ?? f?.properties?.uuid ?? null;
    this.poiEVT.emit(id);
  }
}
