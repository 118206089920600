// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-ugc-track-data {
  display: block;
  margin: var(--wm-feature-details-margin);
}
wm-ugc-track-data .wm-ugc-category-title {
  font-weight: var(--wm-feature-details-title-font-weight);
  font-size: var(--wm-feature-details-title-font-size);
  color: var(--wm-feature-details-title-color);
}
wm-ugc-track-data .wm-ugc-track-data-item {
  font-size: var(--wm-font-mf);
}
wm-ugc-track-data .wm-ugc-track-data-item .wm-ugc-track-data-label {
  font-weight: 400;
  font-family: var(--wm-font-family-content);
}
wm-ugc-track-data .wm-ugc-track-data-item .wm-ugc-track-data-value {
  font-weight: 600;
  font-family: var(--wm-font-family-content);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/ugc-details/ugc-track-data/ugc-track-data.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,wCAAA;AACF;AAAE;EACE,wDAAA;EACA,oDAAA;EACA,4CAAA;AAEJ;AAAE;EACE,4BAAA;AAEJ;AADI;EACE,gBAAA;EACA,0CAAA;AAGN;AADI;EACE,gBAAA;EACA,0CAAA;AAGN","sourcesContent":["wm-ugc-track-data{\n  display: block;\n  margin: var(--wm-feature-details-margin);\n  .wm-ugc-category-title{\n    font-weight: var(--wm-feature-details-title-font-weight);\n    font-size: var(--wm-feature-details-title-font-size);\n    color: var(--wm-feature-details-title-color);\n  }\n  .wm-ugc-track-data-item{\n    font-size: var(--wm-font-mf);\n    .wm-ugc-track-data-label{\n      font-weight: 400;\n      font-family: var(--wm-font-family-content);\n    }\n    .wm-ugc-track-data-value{\n      font-weight: 600;\n      font-family: var(--wm-font-family-content);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
