export const wmPR = {
  'skitouring': 'Esqui de montanha',
  'horse': 'A cavalo',
  'walking': 'Caminhada',
  'running': 'Corrida',
  'cycling': 'Ciclismo',
  'poi_type': 'Pontos de interesse',
  'where': 'Locais',
  'lang': 'Idioma',
  'langPlaceholder': 'Selecionar',
  'from': 'Início',
  'to': 'Fim',
  'ascent': 'Elevação +',
  'descent': 'Elevação -',
  'distance': 'Distância',
  'ele_from': 'Altitude de partida',
  'ele_max': 'Altitude máxima',
  'ele_min': 'Altitude mínima',
  'ele_to': 'Altitude de chegada',
  'difficulty': 'Dificuldade',
  'duration_forward': 'Duração da ida',
  'duration_backward': 'Duração da volta',
  'Filtri': 'Filtros',
  'Cerca': 'Pesquisar',
  'tracce': 'Trilhas',
  'hiking': 'Caminhada',
  'Escursionismo': 'Caminhada',
  'escursionismo': 'Caminhada',
  'i miei percorsi': 'Desenhar percurso',
  'metri': 'Metros',
  'Vedi su OpenStreetMap': 'Ver no OpenStreetMap',
  'Descrizione Audio': 'Descrição de áudio',
  'il tuo browser non supporta gli elementi audio': 'Seu navegador não suporta elementos de áudio.',
  'stampa': 'Imprimir',
  'Difficoltà': 'Dificuldade',
  'Scala di difficoltà CAI': 'Escala de dificuldade CAI',
  'Questo percorso non è accessibile': 'Este caminho não é acessível',
  'Descrizione': 'Descrição',
  'Punto di partenza': 'Ponto de partida',
  'Punto di arrivo': 'Ponto de chegada',
  'Dettagli tecnici': 'Detalhes técnicos',
  'Attività': 'Atividades',
  'Come arrivare': 'Como chegar',
  'Bici muscolari': 'Bicicletas comuns',
  'Bici elettriche': 'Bicicletas elétricas',
  'Mezzi pubblici': 'Transporte público',
  'Auto Propria': 'Carro próprio',
  'Punti di interesse': 'Pontos de interesse',
  'Collegamenti esterni': 'Links externos',
  'Downloads': 'Downloads',
  'edit geohub': 'Editar geohub',
  'NCC': 'NCC',
  'Filtri attivi': 'Filtros ativos',
  'Reset filtri': 'Redefinir filtros',
  'Torna alla home': 'Voltar para a página inicial',
  'Risultati': 'Resultados',
  'Caricamento in corso.': 'Carregando.',
  'Spiacenti non ci sono risultati con questi criteri di ricerca.':
    'Desculpe, não há resultados com estes critérios de pesquisa',
  'Bentornato!': 'Boas-vindas!',
  'Accedi con le tue credenziali per continuare.':
    'Faça login com as suas credenciais para continuar.',
  'E-mail': 'E-mail',
  "L'e-mail è obbligatoria": 'O e-mail é obrigatório',
  "Hai inserito un'e-mail non valida": 'Introduziu um e-mail inválido',
  'Password': 'Senha',
  'La password è obbligatoria': 'A senha é obrigatória',
  'Accedi': 'Conecte-se',
  'Hai dimenticato la password?': 'Esqueceu-se da sua senha?',
  'Accedi e scarica i tuoi percorsi preferiti': 'Aceda e descarregue os seus percorsi preferidos',
  'Puoi anche condividerli con i tuoi compagni di viaggio':
    'Também pode dividir com os seus companheiros de viagem',
  'Registrati': 'Registrando',
  'Tracce scaricate': 'Rastreamento escaricado',
  'Utilizzo dati': 'Utilizo dados',
  'Registrazioni': 'Registros',
  'Photos': 'Photos',
  'Waypoint': 'Waypoint',
  'Nome': 'Nome',
  'inserisci il tuo nome': 'Insira o seu nome',
  "E' necessario inserire un nome": 'Um nome deve ser introduzido',
  'Email': 'Email',
  "E' necessario inserire una email": 'É necessário introduzir um email',
  "L'email inserita non è un'email valida": 'O e-mail é inválido',
  'Codice Fiscale': 'Código Fiscal',
  'Perché ti chiediamo il CF?': 'Porque pedimos o CF?',
  'Il codice fiscale inserito non è valido': 'O código fiscal não é válido',
  'inserisci password': 'introduza a senha',
  "E' necessario inserire la password": 'Uma palavra-passe deve ser inserida',
  'Conferma password': 'Confirme a sua senha',
  'Ripeti password': 'Repita a palavra-passe',
  "E' necessario confermare la password": 'A confirmação da palavra-passe é necessária',
  'La conferma non corrisponde con la password inserita sopra':
    'A confirmação não corresponde à palavra-chave acima introduzida',
  'Cliccando su "Registrati" accetti la nostra': 'Ao clicar em "Registar" aceita os nossos',
  'e i': 'e i',
  'Termini e condizioni': 'Termos e Condições',
  "Errore durante la registrazione dell'utente": 'Erro durante o registo do utilizador',
  'Registrazione in corso': 'Registo em andamento',
  'Perchè ti chiediamo il Codice Fiscale?': 'Porque lhe pedimos o Código Fiscal?',
  'Se sei Socia/o CAI inserisci il tuo CF al momento della registrazione. Per te il download delle tappe del Sentiero Italia CAI sarà automaticamente gratuito!':
    'Se é Membro CAI, insira o seu CF no momento da inscrição. Para si, o download dos passos do Sentiero Italia CAI será automaticamente gratuito!',
  "Origine dell'immagine": 'Fonte da imagem',
  'Annulla': 'Cancelar',
  'Dalla libreria': 'Da biblioteca',
  'Scatta una foto': 'Tire uma fotografia',
  'Carica File': 'Carrega ficheiro',
  'Trascina qui il tuo file o clicca per selezionare':
    'Arrastra o seu ficheiro ou clica para selecionar',
  'Accetta i seguenti formati': 'Aceita os seguintes formatos',
  'Tipo di file non supportato': 'Tipo de ficheiro não suportado',
  'Il file non contiene una geometria valida': 'O ficheiro não contém uma geometria válida',
  'Errore durante la lettura del file, riprova':
    'Errore durante a leitura do ficheiro, tente novamente',
  'Si è verificato un errore durante il salvataggio della traccia. Riprova':
    'Ocorreu um erro ao salvar a trilha. Tente novamente',
  'La traccia è stata salvata correttamente': 'A trilha foi salva corretamente',
  'Scarica il tracciato GPX': 'Descarregar o rastreio GPX',
  'Scarica il tracciato KML': 'Descarregar o rastreio KML',
  'Scarica il tracciato GEOJSON': 'Descarregar o rastreio GEOJSON',
  'Link utili': 'Links úteis',
  'apri pdf': 'Abrir PDF',
  'pendenza': 'Pendência',
  'Galleria': 'Galeria',
  'resetta la memoria': 'Reiniciar a memória',
  'rimani acceso': 'Ficar ligado',
  'forza il dispositivo a non spegnersi': 'Forçar o dispositivo a não desligar',
  'PAGINE': 'PÁGINAS',
  'I tuoi dati': 'Os seus dados',
  'pages.profile.projectlink': 'Projeto',
  'pages.profile.disclaimerlink': 'Desculpas',
  'pages.profile.creditslink': 'Créditos',
  'pages.profile.privacylink': 'Privacidade',
  'pages.profile.title': 'Perfil',
  'MEMORIA': 'MEMÓRIA',
  'la distanza espressa in metri che indica la cadenza di rilevamento posizione, più il distance filter è minore piu il consumo della batteria sarà maggiore.':
    'Distância expressa em metros que indica a frequência de detecção de posição, quanto menor for o filtro de distância, maior será o consumo da bateria.',
  "Per registrare tracce e poi correttamente, abilita l'autorizzazione alla posizione nelle impostazioni":
    'Para registar trilhas e pontos de interesse corretamente, ative a permissão de localização nas definições.',
  'I miei percorsi': 'Minhas rotas',
  'Sentieri': 'Trilhas',
  '<span class="green">Livello 1: tratti non interessati dall\'alta quota (quota minore di {{orange}} metri)</span>':
    '<span class="green">Nível 1: seções não interessadas pela alta altitude (altitude menor que {{orange}} metros)</span>',
  '<span class="orange">Livello 2: tratti parzialmente in alta quota (quota compresa tra {{orange}} metri e {{red}} metri)</span>':
    '<span class="orange">Nível 2: seções parcialmente em alta altitude (altitude entre {{orange}} metros e {{red}} metros)</span>',
  '<span class="red">Livello 3: in alta quota (quota superiore {{red}} metri)</span>':
    '<span class="red">Nível 3: seções em alta altitude (altitude superior a {{red}} metros)</span>',
  'Un utente è già stato registrato con questa email.': 'Um usuário já foi registrado com este e-mail.',
  'L\'email inserita non è corretta. Per favore, riprova.': 'O e-mail inserido está incorreto. Por favor, tente novamente.',
  'La password inserita non è corretta. Per favore, riprova.': 'A senha inserida está incorreta. Por favor, tente novamente.',
  'Errore': 'Erro',
};
