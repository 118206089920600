// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-updated-at {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-size: var(--wm-font-xsm);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/box/udpated-at/updated-at.component.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,qBAAA;EACA,6BAAA;AAAJ","sourcesContent":["\nwm-updated-at{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-end;\n    font-size: var(--wm-font-xsm);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
