// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  ion-item {
    padding: 0;
    i{
      color: var(--wm-color-icon, var(--ion-color-primary));
    }
    ion-label {
      font-weight: 600;
      color: var(wm-feature-details-description-color), var(--wm-color-dark);
    }
  }
`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/phone/phone.component.ts"],"names":[],"mappings":";EACE;IACE,UAAU;IACV;MACE,qDAAqD;IACvD;IACA;MACE,gBAAgB;MAChB,sEAAsE;IACxE;EACF","sourcesContent":["\n  ion-item {\n    padding: 0;\n    i{\n      color: var(--wm-color-icon, var(--ion-color-primary));\n    }\n    ion-label {\n      font-weight: 600;\n      color: var(wm-feature-details-description-color), var(--wm-color-dark);\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
