// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-tab-description {
  display: block;
  margin: var(--wm-feature-details-margin);
}
wm-tab-description .webmapp-pageroute-tabdescription-description {
  padding: 10px;
  margin-bottom: 10px;
  color: var(--wm-feature-details-description-color);
}
wm-tab-description .webmapp-pageroute-tabdescription-title {
  font-weight: var(--wm-feature-details-title-font-weight);
  font-size: var(--wm-feature-details-title-font-size);
  color: var(--wm-feature-details-title-color);
}
wm-tab-description .webmapp-pageroute-tabdescription-photoslider {
  padding: 20px 0;
  overflow: visible;
}
wm-tab-description .webmapp-pageroute-tabdescription-photoslider .webmapp-pageroute-tabdescription-photo {
  height: 150px;
  width: 250px;
}
wm-tab-description .webmapp-pageroute-tabdescription-photoslider .webmapp-pageroute-tabdescription-photo::part(image) {
  border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/tab-description/tab-description.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,wCAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,kDAAA;AACJ;AAEE;EACE,wDAAA;EACA,oDAAA;EACA,4CAAA;AAAJ;AAGE;EACE,eAAA;EACA,iBAAA;AADJ;AAGI;EACE,aAAA;EACA,YAAA;AADN;AAEM;EACE,mBAAA;AAAR","sourcesContent":["wm-tab-description{\n  display: block;\n  margin: var(--wm-feature-details-margin);\n\n  .webmapp-pageroute-tabdescription-description {\n    padding: 10px;\n    margin-bottom: 10px;\n    color: var(--wm-feature-details-description-color);\n  }\n\n  .webmapp-pageroute-tabdescription-title {\n    font-weight: var(--wm-feature-details-title-font-weight);\n    font-size: var(--wm-feature-details-title-font-size);\n    color: var(--wm-feature-details-title-color);\n  }\n\n  .webmapp-pageroute-tabdescription-photoslider {\n    padding: 20px 0;\n    overflow: visible;\n\n    .webmapp-pageroute-tabdescription-photo {\n      height: 150px;\n      width: 250px;\n      &::part(image) {\n        border-radius: 15px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
