// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    ion-list {
      padding: 0;
      ion-item {
        i{
          color: var(--wm-color-icon, var(--ion-color-primary));
        }
        ion-label {
          font-weight: 600;
          color: var(wm-feature-details-description-color), var(--wm-color-dark);
        }
      }
    }
  `, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/related-urls/related-urls.component.ts"],"names":[],"mappings":";IACI;MACE,UAAU;MACV;QACE;UACE,qDAAqD;QACvD;QACA;UACE,gBAAgB;UAChB,sEAAsE;QACxE;MACF;IACF","sourcesContent":["\n    ion-list {\n      padding: 0;\n      ion-item {\n        i{\n          color: var(--wm-color-icon, var(--ion-color-primary));\n        }\n        ion-label {\n          font-weight: 600;\n          color: var(wm-feature-details-description-color), var(--wm-color-dark);\n        }\n      }\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
