// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-profile-popup .wm-profile-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 10px;
  cursor: pointer;
  height: 40px;
  position: relative;
  background: #ffffff !important;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
wm-profile-popup .wm-profile-button.logged-in {
  color: #ffffff !important;
  background-color: var(--wm-color-primary) !important;
}
wm-profile-popup .wm-profile-content {
  position: absolute;
  background-color: white;
  padding: 10px;
  top: calc(2% + 60px + env(safe-area-inset-top));
  right: 1%;
  width: 350px;
  z-index: 1;
  border-radius: 30px;
  min-height: 100px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.4);
}
wm-profile-popup .wm-profile-content ion-button {
  text-transform: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/profile/profile-popup/profile-popup.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,SAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,8BAAA;EACA,2CAAA;EACA,kBAAA;AAAJ;AAEI;EACE,yBAAA;EACA,oDAAA;AAAN;AAGE;EACE,kBAAA;EACA,uBAAA;EACA,aAAA;EACA,+CAAA;EACA,SAAA;EACA,YAAA;EACA,UAAA;EACA,mBAAA;EACA,iBAAA;EAEA,+CAAA;AADJ;AAGI;EACE,oBAAA;AADN","sourcesContent":["wm-profile-popup {\n  .wm-profile-button {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding: 8px 14px;\n    gap: 10px;\n    cursor: pointer;\n    height: 40px;\n    position: relative;\n    background: #ffffff !important;\n    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.2);\n    border-radius: 4px;\n\n    &.logged-in {\n      color: #ffffff !important;\n      background-color: var(--wm-color-primary) !important;\n    }\n  }\n  .wm-profile-content {\n    position: absolute;\n    background-color: white;\n    padding: 10px;\n    top: calc(2% + 60px + env(safe-area-inset-top));\n    right: 1%;\n    width: 350px;\n    z-index: 1;\n    border-radius: 30px;\n    min-height: 100px;\n    -webkit-box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.4);\n    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.4);\n\n    ion-button {\n      text-transform: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
