// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-modal-media {
  position: fixed;
  border-radius: 15px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  z-index: 999999;
  height: -moz-fit-content;
  height: fit-content;
  width: 50%;
  padding: 10px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}
wm-modal-media img {
  border-radius: 15px;
  max-height: 89vh;
}
wm-modal-media .wm-modal-media-close,
wm-modal-media .wm-modal-media-left,
wm-modal-media .wm-modal-media-right {
  position: absolute;
  padding: 10px;
  margin: 5px;
  border-radius: 15px;
}
wm-modal-media .wm-modal-media-close {
  top: 0;
  right: 0;
}
wm-modal-media .wm-modal-media-left,
wm-modal-media .wm-modal-media-right {
  font-size: 30px;
  border-radius: 25px;
}
wm-modal-media .wm-modal-media-left {
  top: 45%;
  left: -20px;
}
wm-modal-media .wm-modal-media-right {
  top: 45%;
  right: -20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/ugc-medias/modal-media/wm-modal-media.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,uBAAA;EACA,eAAA;EACA,wBAAA;EAAA,mBAAA;EACA,UAAA;EACA,aAAA;EACA,2BAAA;EAAA,sBAAA;EACA,4BAAA;EAAA,uBAAA;AACF;AAAE;EACE,mBAAA;EACA,gBAAA;AAEJ;AAAE;;;EAGE,kBAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;AAEJ;AAAE;EACE,MAAA;EACA,QAAA;AAEJ;AAAE;;EAEE,eAAA;EACA,mBAAA;AAEJ;AAAE;EACE,QAAA;EACA,WAAA;AAEJ;AAAE;EACE,QAAA;EACA,YAAA;AAEJ","sourcesContent":["wm-modal-media {\n  position: fixed;\n  border-radius: 15px;\n  margin: auto;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  background-color: white;\n  z-index: 999999;\n  height: fit-content;\n  width: 50%;\n  padding: 10px;\n  max-width: fit-content;\n  max-height: fit-content;\n  img {\n    border-radius: 15px;\n    max-height: 89vh;\n  }\n  .wm-modal-media-close,\n  .wm-modal-media-left,\n  .wm-modal-media-right {\n    position: absolute;\n    padding: 10px;\n    margin: 5px;\n    border-radius: 15px;\n  }\n  .wm-modal-media-close {\n    top: 0;\n    right: 0;\n  }\n  .wm-modal-media-left,\n  .wm-modal-media-right {\n    font-size: 30px;\n    border-radius: 25px;\n  }\n  .wm-modal-media-left {\n    top: 45%;\n    left: -20px;\n  }\n  .wm-modal-media-right {\n    top: 45%;\n    right: -20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
